<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col lg="2" md="2" sm="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            </b-col>

            <b-col lg="3" md="3" sm="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 ">
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="leadOptions" v-model="sourceOption" valueFormat="object" :limit="2" class="per-page-selector d-inline-block w-100" placeholder="Lead Status" />
            </b-col>

            <b-col lg="3" md="3" sm="12" class="d-flex align-items-center justify-content-end">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
              <b-button @click="clearDate()" class="ml-1" variant="success">Clear</b-button>
            </b-col>

            <b-col class="d-flex align-items-center justify-content-end" lg="3" md="3" sm="12">
              <!-- <div>
                  <b-form-checkbox
                  v-model="includeJunk"
                  class="mt-50"
                  title="Include Junk Records"
                  name="Include Junk Records"
                  switch
                  inline
                  >
                  Include Junk Records
                  </b-form-checkbox>

                  <b-button
                  style="background-color:$backgroundColor"
                  @click="generateReport"
                  v-if="$Can('inventory_create')"
                  >
                  <span class="text-nowrap">Generate Report</span>
                  </b-button>
                  </div> -->
            </b-col>

            <b-col class="d-flex align-items-center justify-content-end" lg="1" md="1" sm="12">
              <div>
                <b-button variant="primary" router-link to="/leads/leads-add" v-if="$Can('lead_create')">
                  <span class="text-nowrap">Add Lead</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listLeads"
          :filter="filter"
          filter-debounce="250"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-selected="onRowSelected"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="leadHeaders"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
          </template>
          <!-- Column: firstName -->
          <template #cell(firstName)="data">
            {{ data.item.firstName ? data.item.firstName : 'N/A' }}
          </template>
          <!-- Column: lastName -->
          <template #cell(lastName)="data"> {{ data.item.lastName ? data.item.lastName : 'N/A' }}</template>
          <!-- Column: email -->
          <template #cell(email)="data">
            {{ data.item.email ? data.item.email : 'N/A' }}
          </template>
          <!-- Column: phoneNumber -->
          <template #cell(phone)="data">
            {{ data.item.phone ? data.item.phone : 'N/A' }}
          </template>

          <!-- <template #cell(user)="data">
            <span v-if="data.item.userId != '0'">{{ data.item.user ? data.item.user : 'NOT ASSIGNED' }}</span>
            <span v-if="data.item.userId == '0'"> ALL USERS </span>
          </template> -->
          <!-- Column: Source -->

          <template #cell(source)="data">
            <b-badge v-if="data.item.source == 'website' || data.item.source == 'WEBSITE'" pill variant="light-primary" class="badge-glow">WEBSITE</b-badge>
            <b-badge v-if="data.item.source == 'user' || data.item.source == 'USER'" pill variant="light-warning" class="badge-glow">PORTAL</b-badge>
          </template>

          <template #cell(channel)="data">
            <b-badge v-if="data.item.channel == null"> N/A </b-badge>
            <b-badge v-else pill class="bg-insta">{{ data.item.channel }}</b-badge>
          </template>

          <template #cell(method)="data">
            <b-badge v-if="data.item.method == 'SMS'" pill variant="success" class="badge-glow">SMS</b-badge>
            <b-badge v-if="data.item.method == 'PHONE'" pill variant="info" class="badge-glow">PHONE</b-badge>
            <b-badge v-if="data.item.method == 'EMAIL'" pill variant="primary" class="badge-glow">EMAIL</b-badge>
            <b-badge v-if="data.item.method == 'LETTER'" pill variant="warning" class="badge-glow">LETTER</b-badge>
            <b-badge v-if="data.item.method == 'WEBSITE'" pill variant="secondary" class="badge-glow">WEBSITE</b-badge>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="secondary" class="badge-glow">Unassigned</b-badge>
            <b-badge v-if="data.item.status == 'B'" pill variant="warning" class="badge-glow">Open</b-badge>
            <b-badge v-if="data.item.status == 'C'" pill variant="primary" class="badge-glow">In Progress</b-badge>
            <b-badge v-if="data.item.status == 'D'" pill variant="success" class="badge-glow">Completed</b-badge>
            <b-badge v-if="data.item.status == 'E'" pill variant="info" class="badge-glow">Created Quote</b-badge>
            <b-badge v-if="data.item.status == 'J'" pill variant="info" class="badge-glow">Junk</b-badge>
            <b-badge v-if="data.item.status == 'P'" pill variant="secondary" class="badge-glow">Lost</b-badge>
            <b-badge v-if="data.item.status == 'F'" pill variant="success" class="badge-glow">Win</b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" v-if="$Can('lead_access')">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'leads-preview',
                  params: {
                    id: data.item.id,
                    firstName: data.item.firstName,
                    lastName: data.item.lastName,
                    source: data.item.source,
                    phone: data.item.phone,
                    email: data.item.email,
                    message: data.item.message,
                    method: data.item.method,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'leads-edit', params: { id: data.item.id } }" v-if="$Can('lead_edit')">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('lead-access')" v-if="where == 'leads-list'" @click="$router.push({ name: 'junk-leads-list' })">Show junk records</a>
        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('lead-access')" v-else @click="$router.push({ name: 'leads-list' })">Show active records</a>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import { VBTooltip, BTable, BProgress, BBadge } from 'bootstrap-vue';
import router from '@/router';
import leadStoreModule from './leadStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import flatPickr from 'vue-flatpickr-component';

import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Treeselect,
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    router,
    store,
    leadStoreModule,
    ToastificationContent,
    vSelect,
    VBTooltip,
    flatPickr,
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'lead';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, leadStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const leadOptions = [
      { id: 'A', label: 'Unassigned', value: 'A' },
      { id: 'B', label: 'Open', value: 'B' },
      { id: 'C', label: 'In Progress', value: 'C' },
      { id: 'D', label: 'Completed', value: 'D' },
      { id: 'E', label: 'Created Quote', value: 'E' },
      { id: 'P', label: 'Lost', value: 'P' },
      { id: 'F', label: 'Win', value: 'F' },
    ];

    return {
      config: {
        dateFormat: 'd-m-Y',
        mode: 'range',
      },
      rangeDate: null,

      where: router.currentRoute.name,
      filterDateStart: null,
      filterDateEnd: null,
      treeStatus: null,
      loading: true,
      sourceOption: null,
      includeJunk: false,
      currentPage: 1,
      leadOptions,

      perPageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['firstName', 'lastName', 'email', 'phoneNumber'],
      totalRows: 0,
      perPage: localStorage.getItem('leadShow') ?? 10,
      leadHeaders: [
        {
          key: 'id',
          label: 'ID',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'firstName',
          label: 'FIRST NAME',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'lastName',
          label: 'LAST NAME',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'phone',
          label: 'PHONE',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'email',
          label: 'EMAIL',
          class: 'text-center',
          thStyle: 'width: 25%',
        },

        {
          key: 'source',
          label: 'SOURCE',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'method',
          label: 'METHOD',
          class: 'text-center',
          thStyle: 'width: 15%',
        },

        {
          key: 'status',
          label: 'STATUS',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'actions',
          label: '#',
          class: 'text-center',
          thStyle: 'width: 5%',
        },
      ],
      colWidthFactor: null,
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        // A column that needs custom formatting
        { key: 'firstName', label: 'Name' },
        { key: 'lastName', label: 'Lastname' },

        'source',
        'email',
        'phone',
        'user',
        'status',
        'actions',
      ],
      listLeads: [],
      fetchLeads: [],
      messageLength: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.getItems();
          }
        }
      },
    },
    sourceOption: {
      handler: function(val, before) {
        let filterObj = {
          perPage: this.perPage,
          status: val ? val : [],
        };

        localStorage.setItem('filterLeadList', JSON.stringify(filterObj));

        this.listLeads = [];
        this.fetchLeads.forEach((element) => {
          if (val == null || val.length == 0) {
            this.listLeads = this.fetchLeads;
          } else {
            val.forEach((filterStatus) => {
              if (element.status == filterStatus.value) {
                this.listLeads.push(element);
              }
            });
          }
          this.totalRows = this.listLeads.length;
        });
      },
    },

    perPage: {
      handler: function(val, before) {
        localStorage.setItem('leadShow', val);
      },
    },
  },

  methods: {
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;

        this.getItems();
      }
    },

    onRowSelected(item) {
      if (this.$Can('lead_access')) {
        router.push({ name: 'leads-preview', params: { id: item[0].id } });
      }
    },

    filterTreeSelect() {
      let treeOptionsStatus = [
        {
          id: 100000,
          label: 'Status',
          children: [],
        },
      ];
      this.leadStatus.forEach((element, index) => {
        let setStatus = {
          id: element.value ? element.value : index,
          label: element.text,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });
      this.leadStatus = treeOptionsStatus;
      this.loading = false;
    },
    generateReport() {
      this.download = true;
      store
        .dispatch('lead/pdfDownload', {
          start: this.filterDateStart,
          end: this.filterDateEnd,
          statusList: this.sourceOption,
          includeJunk: this.includeJunk,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Regenwald Lead Report.pdf');
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
          }
        });
    },

    getItems() {
      store
        .dispatch('lead/fetchLeads', {
          start: this.filterDateStart,
          end: this.filterDateEnd,
        })
        .then((res) => {
          this.listLeads = res.data.leads;
          this.fetchLeads = res.data.leads;
          this.loading = false;
          this.totalRows = res.data.leads.length;
          this.createTable(res.data.headers, res.data.columns);

          this.storageLeadItem();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    createTable(leadheaders, headerColumns) {
      if (leadheaders) {
        leadheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
          if (items == 'message') {
            this.messageLength = headerColumns.find((x) => x.value == items).percentage * 0.4;
          }
        });

        leadheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.leadHeaders.push({
            key: items,
            label: columns.title,
            sortable: true,
            class: 'text-center',
            thStyle: width,
          });
        });
        this.leadHeaders.push({
          key: 'actions',
          class: 'text-center',
          thStyle: 'width: 5%',
        });
        this.filterTreeSelect();
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    storageLeadItem() {
      let key = JSON.parse(localStorage.getItem('filterLeadList'));
      if (key) {
        this.perPage = key.perPage ? key.perPage : 10;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        if (array.length > 0) {
          this.sourceOption = array;
        }
      }
    },
  },
  created() {},
  mounted() {
    this.loading = true;
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.bg-insta {
  background-color: #bc2a8d;
}

.bg-facebook {
  background-color: #4267b2;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-youtube {
  background-color: #ff0000;
}

.bg-linked {
  background-color: #0077b5;
}

.bg-systems {
  background-color: #000000;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
